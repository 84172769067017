
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { RouteLocationRaw } from 'vue-router'
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmTooltip,
  },
  props: {
    icon: {
      type: String,
    },
    image: {
      type: String,
    },
    link: {
      type: Object as PropType<RouteLocationRaw>,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    save: {
      type: String,
    },
    iconClass: {
      type: String,
      default: 'primary--text',
    },
    copy: {
      type: Boolean,
    },
    code: {
      type: String,
    },
    comingSoon: {
      type: Boolean,
    },
    beta: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const { isErrorMode } = useModes()
    const hasGettingStartedButtonsSlot = context.slots['getting-started-buttons']
    const copied = ref(false)
    const copyFunc = (text: string) => {
      copied.value = true
      copyToClipboard(text)
      setTimeout(() => {
        copied.value = false
      }, 3000)
    }

    return {
      isErrorMode,
      hasGettingStartedButtonsSlot,
      copied,
      copyFunc,
    }
  },
})
